import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  isExpanded: Boolean;

  constructor() { }

  ngOnInit() {
  }

  callContact() {
    window.open('tel:+525526299820');
  }

}
