import { LeadService } from '../lead.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-try',
  templateUrl: './try.component.html',
  styleUrls: ['./try.component.scss']
})
export class TryComponent implements OnInit {
  public email2;
  public showMessage2 = false;

  constructor(
    private leadService: LeadService
  ) { }

  ngOnInit() {
  }

  tryService() {
    this.leadService.sendLead(this.email2).subscribe(res => {
      this.showMessage2 = true;
      this.email2 = null;
      setTimeout(() => { this.showMessage2 = false; }, 5000);
    }, err => {
      console.log(err);
    });
  }

}
