import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-erp-fourth',
  templateUrl: './erp-fourth.component.html',
  styleUrls: ['./erp-fourth.component.scss']
})
export class ErpFourthComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  hoverIcon1() {
    (<HTMLImageElement> document.getElementById('icon1')).src = '../../assets/svg/icono-recepciona.svg';
  }
  outIcon1() {
    (<HTMLImageElement>document.getElementById('icon1')).src = '../../assets/svg/icono-recepciona-gray.svg';
  }

  hoverIcon2() {
    (<HTMLImageElement> document.getElementById('icon2')).src = '../../assets/svg/icono-consulta.svg';
  }
  outIcon2() {
    (<HTMLImageElement>document.getElementById('icon2')).src = '../../assets/svg/icono-consulta-gray.svg';
  }

  hoverIcon3() {
    (<HTMLImageElement> document.getElementById('icon3')).src = '../../assets/svg/icono-verifica.svg';
  }
  outIcon3() {
    (<HTMLImageElement>document.getElementById('icon3')).src = '../../assets/svg/icono-verifica-gray.svg';
  }

}
