import { Component, OnInit } from '@angular/core';
import { LeadService } from '../lead.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public email;
  public showMessage = false;
  constructor(
    private leadService: LeadService
  ) { }

  ngOnInit() {
  }

  try() {
    this.leadService.sendLead(this.email).subscribe(res => {
      this.showMessage = true;
      this.email = null;
      setTimeout(() => { this.showMessage = false; }, 5000);
    }, err => {
      console.log(err);
    });
  }

}
