import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LeadService {

  private headers = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;'})
  };

  constructor(
      private http: HttpClient
  ) { }

  sendLead(email): Observable<any> {
    const data = {
      name: '',
      email: email,
      telephone: '',
      comments: 'NXT-ERP',
      emailtype: ''

    };
    // tslint:disable-next-line:max-line-length
    return this.http.post('https://appservices.next-cloud.mx/rest/services/pipeline/nextcloud/nxtlite/1/send/email', data, this.headers).pipe(
      map(res => {
        return res;
      })
    );
  }
}
