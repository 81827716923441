import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MenuComponent } from './menu/menu.component';
import { HomeComponent } from './home/home.component';
import { ErpFirstComponent } from './erp-first/erp-first.component';
import { ScrollToModule } from 'ng2-scroll-to';
import { ErpSecondComponent } from './erp-second/erp-second.component';
import { ErpThirdComponent } from './erp-third/erp-third.component';
import { ErpFourthComponent } from './erp-fourth/erp-fourth.component';
import { IntegrationComponent } from './integration/integration.component';
import { PricesComponent } from './prices/prices.component';
import { PartnersComponent } from './partners/partners.component';
import { TryComponent } from './try/try.component';
import { FooterComponent } from './footer/footer.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    HomeComponent,
    ErpFirstComponent,
    ErpSecondComponent,
    ErpThirdComponent,
    ErpFourthComponent,
    IntegrationComponent,
    PricesComponent,
    PartnersComponent,
    TryComponent,
    FooterComponent
    ],
  imports: [BrowserModule, NgbModule.forRoot(), ScrollToModule, HttpClientModule, FormsModule],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
